window.bright_mls_agents = function ()
{
    
    return {

        location_type: 'location', // location, distance
        counties_label: 'Choose counties...',
        states: [],
        state_values: [
            { id: 1, name: 'MD', disabled: false },
            { id: 2, name: 'VA', disabled: false },
            { id: 3, name: 'DC', disabled: false },
            { id: 4, name: 'DE', disabled: false },
            { id: 5, name: 'PA', disabled: false },
        ],
        get states_label()
        {
            if (this.states.length === 0)
            {
                this.$refs.listbox_button.disabled = true;
                this.$refs.listbox_button.classList.add('cursor-not-allowed', 'opacity-50');
                return 'Choose states...';
            }
            this.$refs.listbox_button.disabled = false;
            this.$refs.listbox_button.classList.remove('cursor-not-allowed', 'opacity-50');
            return this.states.length === 1 ? this.states[0].name : `${this.states.length} selected`;
        },
        counties: [],
        county_values: [],

        init()
        {
            this.get_counties();
            this.set_counties_label();
        },


        set_counties_label()
        {
            if (this.counties.length === 0)
            {
                this.counties_label = 'Choose counties...';
            }
            this.counties_label = this.counties.length === 1 ? this.counties[0].name : `${this.counties.length} selected`;
        },

        get_counties()
        {
            let scope = this;
            scope.counties = [];
            axios.get('/email_marketing/get_counties')
                .then(function (response)
                {
                    if (response.data.counties)
                    {
                        scope.county_values = response.data.counties;
                    }
                });

        },

        set_counties()
        {
            let scope = this;
            scope.counties = [];
            document.querySelectorAll('.county-item').forEach(function (ele)
            {
                ele.classList.add('hidden');
            });
            scope.states.forEach(function (state)
            {
                document.querySelectorAll('.county.' + state.name).forEach(function (ele)
                {
                    ele.closest('li').classList.remove('hidden');
                });
            });
            scope.set_counties_label();

        },

        get_list(ele)
        {
            let scope = this;

            let location_type = scope.location_type;
            let miles = document.querySelector('input[name="miles"]').value;
            let location = document.querySelector('input[name="location"]').value;
            let office_name = document.querySelector('input[name="office_name"]').value;
            let states = JSON.stringify(scope.states);
            let counties = JSON.stringify(scope.counties);
            if(location_type == 'location' && (scope.states.length == 0 || scope.counties.length == 0) && office_name == '')
                {
                    notify('error', 'Please select at least one state and one county');
                    return;
                }
            

            let button_html = ele.innerHTML;
            show_loading_button(ele, 'Getting Data... ');
            remove_form_errors();

            let formData = new FormData();
            formData.append('location_type', location_type);
            formData.append('miles', miles);
            formData.append('location', location);
            formData.append('office_name', office_name);
            formData.append('states', states);
            formData.append('counties', counties);

            axios.post('/heritage_financial/agents/bright_mls_agents/get_list', formData)
                .then(function (response)
                {
                    ele.innerHTML = button_html;
                    scope.$refs.results_div.innerHTML = response.data;
                    scope.$refs.download_button.disabled = false;
                 
                })
                .catch(function (error)
                {
                    display_errors(error, ele, button_html);
                    ele.innerHTML = button_html;
                });
        },

        download_list(ele)
        {
             window.open('/heritage_financial/agents/bright_mls_agents/download_list', '_blank');
        },

        hide_results()
        {
            if(!this.$refs.results_div.querySelector('.box-placeholder'))
            {
                this.$refs.results_div.innerHTML = this.$refs.results_template.innerHTML;
                this.$refs.download_button.disabled = true;
            }
        },

    }

}