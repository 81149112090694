window.view_campaign = function ()
{

    return {

        edit_name: false,
        edit_name_value: '',

        edit_category: false,
        edit_category_value: '',

        show_email_cancelled: false,

        init()
        {

        },

        save_name(batch_id, name)
        {
            let scope = this;
            let formData = new FormData();
            formData.append('batch_id', batch_id);
            formData.append('name', name);

            axios.post('/email_marketing/save_name', formData)
                .then(function (response)
                {
                    scope.$refs.batch_name.innerHTML = name;
                    scope.edit_name = false;
                    notify.success('Name updated');
                })
                .catch(function (error)
                {
                });
        },

        save_category(batch_id, category_id, category)
        {
            let scope = this;
            let formData = new FormData();
            formData.append('batch_id', batch_id);
            formData.append('category_id', category_id);

            axios.post('/email_marketing/save_category', formData)
                .then(function (response)
                {
                    scope.$refs.batch_category.innerHTML = category;
                    scope.edit_category = false;
                    notify.success('Category updated');
                })
                .catch(function (error)
                {
                });
        },

        cancel_email(ele, batch_id)
        {
            let scope = this;
            let button_html = ele.innerHTML;
            show_loading_button(ele, 'Cancelling ... ');

            let formData = new FormData();
            formData.append('batch_id', batch_id);

            if (confirm('Are you sure you want to cancel the unsent emails?'))
            {
                axios.post('/email_marketing/cancel_email', formData)
                    .then(function (response)
                    {
                        setTimeout(function ()
                        {
                            scope.show_email_cancelled = true;
                        }, 2000);
                    })
                    .catch(function (error)
                    {
                    });
            }
        },

    }

}