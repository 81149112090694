
window.view_agent = function (agent_id, view_task_id = null, view_task_type = null, view_edit = null)
{

    return {

        agent_id: agent_id,
        view_task_id: view_task_id,
        view_task_type: view_task_type,
        view_edit: view_edit,
        email_agent: false,
        call_agent: false,
        add_note: false,
        create_task: false,
        view_open_house_details: false,
        create_task_active: false,
        call_outcome: '',
        add_reminder: false,
        task_type: '',
        action: '',

        init()
        {
            let scope = this;
            scope.text_editor_init();
            scope.get_activity('all');
            scope.get_activity('email');
            scope.get_activity('call');
            scope.get_activity('note');
            scope.get_tasks();
            scope.get_agent_transactions();

            if (!scope.view_edit && scope.view_task_id)
            {
                setTimeout(function ()
                {
                    scope.run_task(null, scope.view_task_id, scope.view_task_type);
                }, 1000);
            }
            if (scope.view_edit)
            {
                setTimeout(function ()
                {
                    scope.edit_task(scope.view_task_id, false, true);
                }, 1000);
            }
        },

        get_activity(activity)
        {
            let scope = this;
            axios.get('/heritage_financial/crm/get_activity', {
                params: {
                    activity: activity,
                    agent_id: scope.agent_id,
                },
            })
                .then(function (response)
                {
                    document.getElementById('activity_' + activity).innerHTML = response.data;
                })
                .catch(function (error)
                {

                });
        },

        get_tasks()
        {
            let scope = this;
            axios.get('/heritage_financial/crm/get_tasks', {
                params: {
                    agent_id: scope.agent_id,
                },
            })
                .then(function (response)
                {
                    document.getElementById('activity_tasks').innerHTML = response.data;
                })
                .catch(function (error)
                {

                });
        },

        open_email_agent(clear_forms = true)
        {
            let scope = this;

            scope.email_agent = true;

            if (clear_forms == true)
            {
                scope.clear_forms();
            }

            setTimeout(function ()
            {
                let signature_template = document.getElementById('signature_template');
                if (enable_text_editor_in_development == true)
                {
                    tinyMCE.get('message').setContent('<br><br><br>' + signature_template.innerHTML);
                }
                document.getElementById('subject').focus();
            }, 500);



        },

        send_email_agent(ele)
        {
            let scope = this;
            let button_html = ele.innerHTML;
            show_loading_button(ele, 'Sending Email ... ');
            remove_form_errors();

            let form = document.getElementById('email_agent_form');
            let formData = new FormData(form);
            let body = tinyMCE.get('message').getContent();
            formData.append('body', body);
            formData.append('agent_id', scope.agent_id);

            axios.post('/heritage_financial/crm/send_email_agent', formData)
                .then(function (response)
                {
                    ele.innerHTML = button_html;
                    notify('success', 'Email Successfully Sent');
                    scope.email_agent = false;
                    scope.get_activity('all');
                    scope.get_activity('email');
                })
                .catch(function (error)
                {
                    display_errors(error, ele, button_html);
                });
        },


        open_call_agent()
        {
            let scope = this;

            scope.call_agent = true;
            setTimeout(function ()
            {
                scope.clear_forms();
            }, 200);

        },



        save_call_agent(ele)
        {
            let scope = this;
            let button_html = ele.innerHTML;
            show_loading_button(ele, 'Saving Details... ');
            remove_form_errors();

            let form = document.getElementById('call_agent_form');
            let formData = new FormData(form);
            let call_notes = tinyMCE.get('call_notes').getContent();
            formData.append('call_notes', call_notes);
            formData.append('agent_id', scope.agent_id);

            axios.post('/heritage_financial/crm/save_call_agent', formData)
                .then(function (response)
                {
                    let date = response.data.date;
                    ele.innerHTML = button_html;
                    notify('success', 'Call Details Successfully Saved');
                    scope.call_agent = false;
                    scope.get_activity('all');
                    scope.get_activity('call');

                    scope.$refs.last_contact_date.innerText = date;
                    scope.$refs.last_contact_name.innerText = document.getElementById('last_contact_name_value').value;
                    scope.$refs.last_contact_outcome.innerText = document.getElementById('last_contact_outcome_value').value;
                })
                .catch(function (error)
                {
                    display_errors(error, ele, button_html);
                });
        },

        open_add_note()
        {
            let scope = this;
            scope.clear_forms();
            scope.add_note = true;
            setTimeout(function ()
            {
                tinyMCE.get('notes').focus();
            }, 500);

        },

        save_notes(ele)
        {
            let scope = this;
            let button_html = ele.innerHTML;
            show_loading_button(ele, 'Saving Note ... ');
            remove_form_errors();

            let form = document.getElementById('add_notes_form');
            let formData = new FormData(form);
            let notes = tinyMCE.get('notes').getContent();
            formData.append('agent_id', agent_id);
            formData.set('notes', notes);

            axios.post('/heritage_financial/crm/save_notes', formData)
                .then(function (response)
                {
                    ele.innerHTML = button_html;
                    notify('success', 'Note Successfully Saved');
                    scope.add_note = false;
                    scope.get_activity('all');
                    scope.get_activity('note');

                })
                .catch(function (error)
                {
                    display_errors(error, ele, button_html);
                });
        },


        open_create_task()
        {
            let scope = this;

            scope.create_task = true;
            scope.clear_forms();

            scope.action = 'Add';

            setTimeout(function ()
            {
                document.querySelector('[name=task_title]').focus();
            }, 500);

        },

        save_task(ele)
        {
            let scope = this;
            let button_html = ele.innerHTML;
            show_loading_button(ele, 'Saving Task... ');
            remove_form_errors();

            let form = document.getElementById('create_task_form');
            let formData = new FormData(form);
            let task_notes = tinyMCE.get('task_notes').getContent();
            formData.set('task_notes', task_notes);
            formData.append('agent_id', scope.agent_id);

            axios.post('/heritage_financial/crm/save_task', formData)
                .then(function (response)
                {
                    ele.innerHTML = button_html;
                    notify('success', 'New Task Successfully Saved');
                    scope.create_task = false;
                    scope.get_activity('all');
                    scope.get_tasks();

                    scope.$refs.last_contact_date.innerText = date;
                    scope.$refs.last_contact_name.innerText = document.getElementById('last_contact_name_value').value;
                    scope.$refs.last_contact_outcome.innerText = document.getElementById('last_contact_outcome_value').value;
                })
                .catch(function (error)
                {
                    display_errors(error, ele, button_html);
                });
        },

        run_task(ele, id, task_type)
        {

            if (task_type === 'email')
            {
                document.querySelector('#email_task_id').value = id;
                this.open_email_agent(false);
            } else if (task_type === 'call')
            {
                document.querySelector('#call_task_id').value = id;
                this.call_agent = true;
            }
        },

        complete_task(ele, task_id, completed)
        {
            axios.get('/heritage_financial/crm/complete_task', {
                params: {
                    task_id: task_id,
                    completed: completed
                },
            })
                .then(function (response)
                {
                    if (completed === 1)
                    {
                        let button_div = ele.closest('.button-div');
                        button_div.classList.add('hidden');
                        button_div.nextElementSibling.classList.remove('hidden');
                    } else
                    {
                        let completed_div = ele.closest('.completed-div');
                        completed_div.classList.add('hidden');
                        completed_div.previousElementSibling.classList.remove('hidden');
                    }
                })
                .catch(function (error)
                {

                });
        },

        edit_task(id, reminder = false, on_init = false)
        {
            let scope = this;
            scope.action = 'Edit';
            if (on_init == false)
            {
                scope.clear_forms();
            }
            axios.get('/heritage_financial/crm/edit_task', {
                params: {
                    id: id
                },
            })
                .then(function (response)
                {
                    scope.create_task = true;
                    let task = response.data.task;
                    scope.task_type = task.task_type;
                    scope.$refs.task_id.value = task.id;
                    scope.$refs.task_title.value = task.title;
                    scope.$refs.task_date.value = task.task_date.substring(0, 10);
                    scope.$refs.task_time.value = task.task_date.substring(11, 16);
                    document.querySelector('.date-option.task').querySelector('.date-option-select').classList.add('hidden');
                    document.querySelector('.date-option.task').querySelector('.date-option-input').classList.remove('hidden');
                    scope.show_custom_date(document.querySelector('#task_date'), true);
                    if (task.notification)
                    {
                        scope.add_reminder = true;
                        scope.$refs.reminder_set.checked = true;
                        scope.$refs.reminder_date.value = task.notification.reminder_date.substring(0, 10);
                        scope.$refs.reminder_time.value = task.notification.reminder_date.substring(11, 16);
                        document.querySelector('.date-option.reminder').querySelector('.date-option-select').classList.add('hidden');
                        document.querySelector('.date-option.reminder').querySelector('.date-option-input').classList.remove('hidden');
                        scope.show_custom_date(document.querySelector('#reminder_date'), true);
                    }
                    if (reminder == true)
                    {
                        scope.$refs.reminder_label_icon.classList.add('fa-beat', 'fa-xl');
                        scope.$refs.reminder_set.checked = true;
                        scope.add_reminder = true;
                        scope.$refs.reminder_div.classList.add('p-2', 'rounded-lg', 'border-sky-50', 'border-2', 'bg-sky-50');
                    }
                    if (task.notes)
                    {
                        setTimeout(function ()
                        {
                            if (enable_text_editor_in_development == true)
                            {
                                tinyMCE.get('task_notes').setContent(task.notes);
                            }
                        }, 1000);
                    }
                })
                .catch(function (error)
                {

                });
        },

        delete_task(task_id)
        {
            let scope = this;
            if (confirm('Are you sure you want to delete this task?'))
            {
                axios.get('/heritage_financial/crm/delete_task', {
                    params: {
                        task_id: task_id
                    },
                })
                    .then(function (response)
                    {
                        notify('success', 'Task Successfully Deleted');
                        scope.get_tasks();
                        scope.get_activity('all');
                    })
                    .catch(function (error)
                    {

                    });
            }
        },

        get_agent_transactions()
        {
            let scope = this;

            ['listings_sales', 'listings_rentals', 'contracts_sales', 'contracts_rentals'].forEach(function (relationship)
            {

                axios.get('/heritage_financial/crm/get_agent_transactions', {
                    params: {
                        agent_id: scope.agent_id,
                        relationship: relationship
                    },
                })
                    .then(function (response)
                    {
                        document.getElementById(relationship).innerHTML = response.data;
                    })
                    .catch(function (error)
                    {

                    });

            });
        },

        view_open_house(id)
        {
            let scope = this;
            scope.view_more_details = false;
            document.getElementById('open_house_details_div').innerHTML = '';

            scope.view_open_house_details = true;

            axios.get('/heritage_financial/open_houses/view_open_house', {
                params: {
                    id: id
                },
            })
                .then(function (response)
                {
                    document.getElementById('open_house_details_div').innerHTML = response.data;
                })
                .catch(function (error)
                {

                });
        },

        update_agent_status(status)
        {
            let scope = this;
            axios.get('/heritage_financial/crm/update_agent_status', {
                params: {
                    agent_id: scope.agent_id,
                    status: status
                },
            })
                .then(function (response)
                {
                    notify('success', 'Agent Status Successfully Updated');
                })
                .catch(function (error)
                {

                });
        },

        update_loan_officer(agent_ids, loan_officer_id, redirect = null)
        {
            let cont = true;
            if (redirect)
            {
                cont = false;
                if (confirm('Are you sure you want to remove this agent from your assigned agents?'))
                {
                    cont = true;
                }
            }

            if (cont == true)
            {

                let formData = new FormData();
                formData.append('agent_ids', agent_ids);
                formData.append('loan_officer_id', loan_officer_id);

                axios.post('/heritage_financial/crm/assign_loan_officer', formData)
                    .then(function (response)
                    {
                        if (loan_officer_id == '')
                        {
                            if (redirect)
                            {
                                window.location.href = '/heritage_financial/crm';
                            } else
                            {
                                notify('success', 'Agent Successfully Unassigned');
                            }

                        } else
                        {
                            notify('success', 'Agent Successfully Assigned To Loan Officer');
                        }
                    })
                    .catch(function (error)
                    {
                    });

            }


        },

        undo_loan_officer_change(ele)
        {
            let scope = this;
            let value = ele.getAttribute('data-default-value');
            ele.value = value;
        },

        clear_forms()
        {

            let form = document.getElementById('email_agent_form');
            if (form)
            {
                form.reset();
            }
            form = document.getElementById('call_agent_form');
            if (form)
            {
                form.reset();
            }
            form = document.getElementById('create_task_form');
            if (form)
            {
                form.reset();
            }
            if (enable_text_editor_in_development == true)
            {
                tinyMCE.get('message').setContent('');
                tinyMCE.get('call_notes').setContent('');
                tinyMCE.get('notes').setContent('');
            }

            this.$refs.reminder_label_icon.classList.remove('fa-beat', 'fa-xl');
            this.$refs.reminder_div.classList.remove('p-2', 'rounded-lg', 'border-sky-50', 'border-2', 'bg-sky-50');

            this.create_task_active = false;
            remove_form_errors();
            document.querySelectorAll('.create-task').forEach(function (check)
            {
                check.checked = false;
            });

            remove_file_names(document.querySelector('[name="attachments[]"]'));


            document.querySelectorAll('.date-field-input').forEach(function (input)
            {
                input.setAttribute('name', '');
            });
            document.querySelectorAll('.date-field-select').forEach(function (input)
            {
                input.setAttribute('name', input.getAttribute('data-default-name'));
            });

            // TODO end changes

            document.querySelectorAll('.date-option-select').forEach(function (input)
            {
                input.classList.remove('hidden');
            });
            document.querySelectorAll('.date-option-input').forEach(function (input)
            {
                input.classList.add('hidden');
            });
            this.call_outcome = '';
            this.task_type = '';
            this.add_reminder = false;

            document.querySelectorAll('.date-option-select').forEach(function (select)
            {
                select.classList.remove('hidden');
            });
            document.querySelectorAll('.date-option-input').forEach(function (input)
            {
                input.classList.add('hidden');
            });

            document.querySelector('#call_task_id').value = '';
            document.querySelector('#email_task_id').value = '';

        },

        show_custom_date(ele, force = false)
        {
            if (ele.value == 'custom' || force == true)
            {
                let task = ele.closest('.date-option');
                let date_picker = task.querySelector('.date-option-input').querySelector('.date-picker');
                task.querySelector('.date-option-select').classList.add('hidden');
                task.querySelector('.date-option-input').classList.remove('hidden');
                let ele_name = ele.getAttribute('name');
                ele.setAttribute('name', ele_name + '_removed');
                date_picker.setAttribute('name', ele_name);
                date_picker.click();
            }
        },

        text_editor_init()
        {
            let options = {
                selector: '.text-editor-basic',
                height: 200,
                statusbar: false,
                menubar: false,
                plugins: [
                    'advlist', 'autolink', 'lists', 'image', 'autoresize'
                ],
                toolbar: 'bold italic forecolor backcolor bullist numlist outdent indent image',
                relative_urls: false,
                document_base_url: location.hostname,
            }
            text_editor(options);

            let options_advanced = {
                selector: '.text-editor',
                height: 300,
                autoresize_min_height: 300,
                autoresize_max_height: 600,
                min_height: 300,
                max_height: 600,
                statusbar: false,
                menubar: false,
                plugins: [
                    'advlist', 'autolink', 'lists', 'image', 'visualblocks', 'code', 'fullscreen', 'media', 'table', 'autoresize'
                ],
                toolbar: 'undo redo bold italic forecolor backcolor image bullist numlist outdent indent code',
                table_toolbar: 'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
                relative_urls: false,
                document_base_url: location.hostname,
            }
            text_editor(options_advanced);
        },

    }

}