let email_employees = false;
if (document.URL.match(/email_employees/))
{
    email_employees = true;
}

window.email_list = function (recipient_type, active_email = null)
{

    return {

        recipient_type: recipient_type,
        active_email: active_email,
        show_email_option: false,
        email_modal: false,
        email_recipients_modal: false,
        show_email_recipients_modal: false,
        signature: '',

        init()
        {
            let scope = this;
            scope.get_signature();
            setTimeout(function ()
            {
                scope.message_editor('#message');
            }, 500);
            if (email_employees == true)
            {
                scope.get_emails();
            }
            scope.clear_recipients();
        },

        clear_recipients()
        {
            document.querySelectorAll('.checkbox').forEach(function (checkbox)
            {
                checkbox.addEventListener('change', function ()
                {
                    if (checkbox.checked)
                    {
                        if (checkbox.classList.contains('agents'))
                        {
                            document.querySelectorAll('.checkbox').forEach(function (non_agent_checkbox)
                            {
                                if (!non_agent_checkbox.classList.contains('agents'))
                                {
                                    non_agent_checkbox.checked = false;
                                }
                            });
                        } else
                        {
                            document.querySelectorAll('.checkbox.agents').forEach(function (checkbox)
                            {
                                checkbox.checked = false;
                            });
                        }
                    }
                });
            });
        },

        check_all(checked, container = null)
        {
            container = container || document;
            container.querySelectorAll('.recipient-checkbox').forEach(function (checkbox)
            {
                checkbox.checked = checked;
            });
        },
        show_email_button()
        {
            if (document.querySelectorAll('.recipient-checkbox:checked').length > 0)
            {
                this.show_email_option = true;
            } else
            {
                this.show_email_option = false;
            }
        },
        show_recipients_added()
        {
            let div = this.$refs.recipients_added;
            div.innerHTML = '';
            document.querySelectorAll('.recipient-checkbox:checked').forEach(function (recipient)
            {
                let html;
                if (recipient.classList.contains('agents'))
                {
                    html = '<div class="my-1 border-b"> \
                        <div>'+ recipient.getAttribute('data-company') + ' Agents</div> \
                    </div>';
                } else
                {
                    let name = recipient.getAttribute('data-name');
                    let email = recipient.getAttribute('data-email');
                    let company = recipient.getAttribute('data-company') || '';
                    html = '<div class="text-xs grid grid-cols-8 my-1 border-b"> \
                        <div class="col-span-2">'+ name + '</div> \
                        <div class="col-span-3">'+ email + '</div> \
                        <div class="col-span-3">'+ company + '</div> \
                    </div>';
                }
                div.insertAdjacentHTML('beforeend', html);
            });
        },
        show_email_modal()
        {
            let scope = this;
            if (document.querySelectorAll('.recipient-checkbox:checked').length > 0)
            {
                scope.email_modal = true;
                scope.show_recipients_added();
                scope.email_recipients_modal = false;
                scope.$refs.attachments.value = '';
            } else
            {
                alert('You must first add recipients');
            }
        },
        send_email(ele, company)
        {
            let scope = this;
            let recipients = [];
            if (company == '')
            {
                company = document.querySelector('#email_list_form').querySelector('[name="company"]').value;
            }
            console.log(company);
            document.querySelectorAll('.recipient-checkbox:checked').forEach(function (recipient)
            {
                let details;
                if (recipient.classList.contains('agents'))
                {
                    details = {
                        'id': recipient.getAttribute('data-id'),
                        'company': recipient.getAttribute('data-company'),
                    }
                } else
                {
                    details = {
                        'id': recipient.getAttribute('data-id'),
                        'name': recipient.getAttribute('data-name'),
                        'first_name': recipient.getAttribute('data-first-name'),
                        'email': recipient.getAttribute('data-email'),
                    }
                }
                recipients.push(details);
            });
            recipients = JSON.stringify(recipients);

            let message = tinymce.activeEditor.getContent();

            let button_html = ele.innerHTML;
            show_loading_button(ele, 'Sending Emails ... ');
            remove_form_errors();

            let form = document.querySelector('#email_list_form');
            let formData = new FormData(form);
            formData.append('recipient_type', scope.recipient_type);
            formData.append('recipients', recipients);
            formData.append('message', message);
            formData.append('company', company);

            axios.post('/email/send_email', formData)
                .then(function (response)
                {
                    ele.innerHTML = button_html;
                    scope.email_modal = false;
                    notify('success', 'Emails Successfully Sent');
                    setTimeout(function ()
                    {
                        scope.get_emails();
                    }, 3000);
                })
                .catch(function (error)
                {
                    display_errors(error, ele, button_html);
                });
        },

        message_editor(ele)
        {

            let scope = this;

            let options = {
                selector: ele,
                height: 200,
                max_height: 500,
                menubar: '',
                statusbar: false,
                plugins: 'image table code autoresize',
                toolbar: 'undo redo | table | bold italic underline | forecolor backcolor | align outdent indent |  numlist | image | formatselect fontselect fontsizeselect | code |',
                table_toolbar: 'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
                relative_urls: false,
                document_base_url: location.hostname,
                toolbar_location: 'bottom',

                setup: function (editor)
                {
                    editor.on('init', function (e)
                    {
                        if (enable_text_editor_in_development == true)
                        {
                            editor.setContent('Hello %%FirstName%%,<br><br><br><br>' + scope.signature);
                        }
                    });
                }
            }
            text_editor(options);

        },
        get_signature()
        {
            let scope = this;
            axios.get('/global/get_signature')
                .then(function (response)
                {
                    scope.signature = response.data;
                })
                .catch(function (error)
                {

                });
        },

        get_emails(search = null)
        {
            let scope = this;
            axios.get('/email/get_emails', {
                params: {
                    search: search
                },
            })
                .then(function (response)
                {
                    scope.$refs.emails_div.innerHTML = response.data;
                    setTimeout(function ()
                    {
                        let new_active_email = parseInt(scope.$refs.active_email.value);
                        if (scope.active_email != new_active_email)
                        {
                            scope.active_email = new_active_email;
                        }
                        scope.show_email(scope.active_email);
                    }, 400);
                })
                .catch(function (error)
                {

                });
        },

        show_email(id)
        {
            if (id)
            {
                let scope = this;
                axios.get('/email/get_email', {
                    params: {
                        id: id
                    },
                })
                    .then(function (response)
                    {
                        scope.$refs.email_view.innerHTML = response.data.email.message;
                        scope.$refs.subject.innerHTML = truncate_string(response.data.email.subject, 80);
                        scope.$refs.from.innerHTML = response.data.email.user.name;
                        scope.$refs.date_sent.innerHTML = response.data.email.date_sent;
                        scope.$refs.to_count.innerHTML = response.data.email.recipients_total + ' Recipients';

                        if (response.data.email.recipients_total > 100)
                        {
                            scope.$refs.to_count_button.disabled = true;
                        }
                    })
                    .catch(function (error)
                    {

                    });
            }
        },

        show_recipients(ele)
        {

            let scope = this;
            let button_html = ele.innerHTML;
            ele.disabled = true;
            show_loading_button(ele, 'Getting list, please wait ... ');

            axios.get('/email/get_recipients', {
                params: {
                    id: scope.active_email,
                },
            })
                .then(function (response)
                {
                    scope.show_email_recipients_modal = true;
                    let recipients = response.data.recipients;
                    let recipients_type = response.data.recipient_type;

                    scope.$refs.recipients_div.innerHTML = '';

                    recipients.forEach(function (recipient)
                    {
                        let name, email;
                        if (recipient.user)
                        {
                            name = recipient.user.name;
                            email = recipient.user.email;
                        }

                        if (recipients_type == 'lender')
                        {
                            name = recipient.lender.company_name;
                            email = recipient.lender.account_exec_email;
                        }
                        let html = `
                        <div class="flex justify-start space-x-4 border-b p-1">
                            <div class="w-28" x-ref="button_div_`+ recipient.id + `">
                                <a href="javascript:void(0)" class="text-sky-600" @click="resend_email($el, `+ recipient.id + `, ` + scope.active_email + `, $refs.button_div_` + recipient.id + `)">Resend <i class="fa-duotone fa-sync ml-2"></i></a>
                            </div>
                            <div class="flex justify-between flex-grow">
                                <div>`+ name + `</div>
                                <div>`+ email + `</div>
                            </div>
                        </div>`;
                        scope.$refs.recipients_div.innerHTML += html;
                    });

                    ele.innerHTML = button_html;
                    ele.disabled = false;
                })
                .catch(function (error)
                {

                });
        },

        resend_email(ele, recipient_id, email_id, button_div)
        {
            if (confirm('Are you sure you want to resend this email?'))
            {
                let scope = this;
                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Resending ');

                axios.get('/email/resend_email', {
                    params: {
                        recipient_id: recipient_id,
                        email_id: email_id
                    },
                })
                    .then(function (response)
                    {
                        button_div.innerHTML = '<span class="text-green-600">Resent <i class="fa-duotone fa-check ml-2"></i></span>';
                        notify('success', 'Email Resent Successfully Resent');
                    })
                    .catch(function (error)
                    {
                        display_errors(error, button_div, button_html);
                    });

            }
        },


    }

}
