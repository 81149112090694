import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/material.css';
import 'tippy.js/animations/scale.css';
import Sortable from 'sortablejs/modular/sortable.complete.esm.js';
import Highcharts from 'highcharts';

import * as FilePond from 'filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import Cropper from 'cropperjs';
import Alpine from 'alpinejs';
import mask from '@alpinejs/mask';
import focus from '@alpinejs/focus';
import collapse from '@alpinejs/collapse';

// import 'sortable-tablesort/sortable.min.css'
import 'sortable-tablesort/sortable.min.js'

window.Sortable = Sortable;
window.tippy = tippy;
window.Highcharts = Highcharts;
window.FilePond = FilePond;
window.Cropper = Cropper;
window.Alpine = Alpine;

Alpine.plugin(mask);
Alpine.plugin(focus);
Alpine.plugin(collapse);
Alpine.start();

FilePond.registerPlugin(FilePondPluginImagePreview);
FilePond.registerPlugin(FilePondPluginFileEncode);
FilePond.registerPlugin(FilePondPluginFileValidateType);

// Sentry.setUser({email: 'john.doe@example.com'});

import './bootstrap';

import './resources/form_elements/form_elements';
import './global';


import './resources/config/config_variables';
import './resources/settings/settings';

import './nav/nav';
import './dashboard/dashboard';
import './admin/queue_monitor';

import './tables/tables';
import './tables/data_tables';

import './reports/reports';
import './reports/transactions_data';
import './reports/title';
import './reports/plus_minus_agents';

import './company_accounts/company_accounts';
import './company_accounts/report_problems';

import './email/email_list';
import './doc_management/admin/forms/forms';
import './doc_management/admin/forms/form_fields';

import './doc_management/admin/checklists/checklists';

import './employees/agents';

import './employees/employees';
import './employees/billing_hf';

import './employees/mortgage';
import './employees/in_house';
import './employees/title';

import './users/users';

import './licenses/company_licenses';
import './licenses/company_licenses';
import './licenses/company_licenses_settings';


import './doc_management/transactions/create';
import './doc_management/transactions/archives';
import './doc_management/transactions/escrow';

import './heritage_financial/loans';
import './heritage_financial/view_loan';
import './heritage_financial/commission_reports';
import './heritage_financial/manager_bonuses';
import './heritage_financial/lenders';
import './heritage_financial/view_lender';
import './heritage_financial/crm';
import './heritage_financial/view_agent';
import './heritage_financial/email_agents';
import './heritage_financial/loan_applications';

import './heritage_financial/bright_mls_agents';

import './heritage_financial/open_houses';

import './marketing/data/address_database';
import './marketing/data/upload_list';

import './marketing/data/upload_list';
import './marketing/hubspot/hubspot';

import './marketing/title/surveys';

import './marketing/leads/home_buyer_leads';
import './marketing/leads/add_leads_to_nutshell';

import './marketing/emails/email_marketing';
import './marketing/emails/create_email_campaign';
import './marketing/emails/view_email_campaign';


import './employees/tokens/tokens';

import './contacts/contacts';

import './chat_gpt/chat_gpt';

import './agents/acknowledgements';
import './agents/sign_acknowledgement';
import './agents/seller_concessions';
import './agents/pre_listings';
import './agents/coming_soon';
import './agents/listing_websites';

import './documents/public_documents';


import './notes/notes';
import './tools/tools';
import './tools/marketing/add_contacts_info_from_lending_pad';
import './tools/webhooks/run_webhook';

import './signatures/signatures';

import './bright_api/data_validation';



import './vendor/form_elements';

import './apps/pay_checks/pay_checks';
import './apps/tracker/tracker';


import './landing_pages/in_house_agents/software_survey';


import './tests/test';



